export function handleFormSubmit(formSelector, url, buttonSelector) {
    let loader = $('.background_loader');
    $(formSelector).submit(function (event) {
        loader.show();
        event.preventDefault();

        var formData = new FormData(this);

        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function (data) {
                $('.alert').remove();

                var response = JSON.parse(data);
                var message = '';

                if (response.error) {
                    message = '<div class="alert alert-danger" role="alert">' + response.message + '</div>';
                } else {
                    message = '<div class="alert alert-success" role="alert">' + response.message + '</div>';
                    $(formSelector + ' .form_input input').val('');
                    $(formSelector + ' .grid-container .grid-item').removeClass('checked');
                    $(formSelector)[0].reset();
                }

                $(buttonSelector).before(message);
                loader.hide();
            }
        });
    });
}

export function generateQr(getUrlQrCode) {
    $(document).on('click', '.btn.generate-qr', function () {
        let loader = $('.background_loader');
        const couponId = $(this).data('coupon-id');

        if (couponId) {
            loader.show();
            $.ajax({
                url: getUrlQrCode,
                data: { couponId: couponId },
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (!data.error) {
                        const qrCodeDiv = $('#qrCodeModal .qr-code');
                        qrCodeDiv.html('<img src="' + data.qrCode + '" alt="QR Code">');
                        $('#qrCodeModal').modal('show');
                    } else {
                        alert('Failed to generate QR code');
                    }
                    loader.hide();
                },
                error: function () {
                    loader.hide();
                }
            });
        }
    });
}

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

$(document).ready(function () {
    function isMobile() {
        return window.innerWidth < 768;
    }

    if (isMobile() && window.location.hash) {
        let target = $(window.location.hash);
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - 50
            }, 800);
        }
    }

    $('a[href*="#"]').on("click", function (e) {
        if (isMobile()) {
            let target = $(this.hash);
            if (target.length) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 50
                }, 800);
                history.pushState(null, null, this.hash);
            }
        }
    });
});